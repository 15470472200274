/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { createRoot } from 'react-dom/client';
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from 'react-router-dom';
import App from './App';
import './css/index.css';

function Tweak() {
  // 👇️ redirect to external URL
  window.location.replace('https://tweak.flashmylight.regent.ch');

  return null;
}

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
  },
  {
    path: '/tweak',
    element: <Tweak />,
  },
  {
    path: '*',
    element: <Navigate to="/" />,
  },
]);

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
);
