import { useEffect, useState } from 'react';
import worker_script from './worker-script.js';

const timeWorker = new Worker(worker_script);

interface FlashProps {
  active: boolean;
  setActive: (newVal: boolean) => void;
  frames: string[];
  timeUnit: number;
  fullScreen: boolean;
  setFullScreen: (newVal: boolean) => void;
}
let idx = 0;

const whiteSvg = <rect className="fill-white h-full w-full" />;
const blackSvg = <rect className="fill-black h-full w-full" />;

function Flash({
  active,
  setActive,
  frames,
  timeUnit,
  fullScreen,
  setFullScreen,
}: FlashProps): JSX.Element {
  const [frame, setFrame] = useState(frames[idx]);

  useEffect(() => {
    timeWorker.onmessage = () => {
      if (idx < frames.length - 1) {
        idx += 1;
      } else {
        idx = 0;
      }
      setFrame(frames[idx]);
    };
  }, [frames]);

  useEffect(() => {
    idx = 0;
    setFrame(frames[0]);
    if (active) {
      timeWorker.postMessage({ turn: 'on' });
    } else {
      timeWorker.postMessage({ turn: 'off' });
    }
  }, [active, frames]);

  useEffect(() => {
    timeWorker.postMessage({ timeUnit: timeUnit });
  }, [timeUnit]);

  return (
    <>
      <div
        className={`border-4 border-gray-300 ${
          fullScreen
            ? 'z-1000 absolute top-0 right-0 left-0 bottom-0 bg-white'
            : 'relative flex flex-col w-full h-auto flex-grow min-h-[33%]'
        }`}
      >
        <svg
          role="img"
          aria-label="Flash"
          className={`w-full ${fullScreen ? 'h-full' : 'h-full flex-grow'}`}
          onClick={() => {
            if (fullScreen) {
              setActive(false);
              setFullScreen(false);
            }
          }}
          onKeyDown={(event) => {
            if (event.key === 'Escape') {
              setActive(false);
              setFullScreen(false);
            }
          }}
        >
          {frame === '1' ? whiteSvg : blackSvg}
        </svg>
      </div>
    </>
  );
}

export default Flash;
