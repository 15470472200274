interface CardProps {
  children: React.ReactNode;
  className?: string;
}

function Card({ children, className = '' }: CardProps): JSX.Element {
  return (
    <div
      className={`bg-white rounded-md m-2 p-2 border border-gray-200 flex gap-2 ${className}`}
    >
      {children}
    </div>
  );
}
export default Card;
