import { HiOutlineWrenchScrewdriver } from 'react-icons/hi2';
import Card from '../Card';

interface DebugInformationProps {
  timeUnit: number;
  setTimeUnit: (active: number) => void;
  flashValue: string;
  manchesterCoded: string;
}

function DebugInformation({
  timeUnit,
  setTimeUnit,
  flashValue,
  manchesterCoded,
}: DebugInformationProps): JSX.Element {
  return (
    <Card className="flex-col items-start border-amber-300">
      <div className="flex font-bold items-center gap-2">
        <HiOutlineWrenchScrewdriver className="h-6 w-6" />
        <span>Debug mode</span>
      </div>
      <div className="flex items-center gap-2">
        <span className="font-bold">Time units (in ms)</span>
        <input
          id="number"
          type="number"
          value={timeUnit}
          onChange={(event) => {
            setTimeUnit(Number.parseInt(event.target.value, 10));
          }}
          className="border-2 border-gray-200 rounded"
        />
      </div>
      <div className="flex items-center gap-2">
        <span className="font-bold">Total duration</span>
        <span>{(timeUnit * manchesterCoded.length) / 1000}s</span>
      </div>
      <div className="flex items-center gap-2">
        <span className="font-bold">Raw value</span>
        <span data-test-id="raw-value">{flashValue}</span>
      </div>
      <div className="flex items-center gap-2">
        <span className="font-bold">Manchester coded value</span>
        <span data-test-id="manchester-coded">{manchesterCoded}</span>
      </div>
    </Card>
  );
}
export default DebugInformation;
