import { FormattedMessage } from "react-intl";
import { Languages } from "../Header";

const aGBUrls = {
	[Languages.DE]: "/agb/",
	[Languages.EN]: "/en/gtc/",
	[Languages.FR]: "/fr/cgv/",
	[Languages.IT]: "/it/condizioni-generali-di-vendita/",
};

function Footer({ language }: { language: Languages }): JSX.Element {
	return (
		<div className="flex justify-around items-center bg-gray-800 text-white mt-2 text-sm py-2">
			<a href={`https://www.regent.ch${aGBUrls[language]}`}>
				<FormattedMessage id="AGB" />
			</a>
			<a
				href={`https://www.regent.ch${
					language === Languages.DE ? "/" : `/${language}/`
				}impressum/`}
			>
				<FormattedMessage id="Impressum" />
			</a>
		</div>
	);
}

export default Footer;
