// https://medium.com/@adithyaviswam/overcoming-browser-throttling-of-setinterval-executions-45387853a826

// Need a worker to avoid Chrome throttling of "setInterval" that causes unprecise frame timing
const workercode = () => {
  let timerInterval: NodeJS.Timeout;
  let time = 0;
  let internTimeUnit = 50;

  self.onmessage = ({ data: { turn, timeUnit } }) => {
    if (timeUnit) {
      internTimeUnit = timeUnit;
    } else {
      if (turn === 'off' || timerInterval) {
        clearInterval(timerInterval);
        time = 0;
      }
      if (turn === 'on') {
        timerInterval = setInterval(() => {
          time += 1;
          self.postMessage({ time });
        }, internTimeUnit);
      }
    }
  };
};

const code = `(${workercode})();`; // Trick to convert the above function to string
const blob = new Blob([code], {
  type: 'application/javascript',
});
const worker_script = URL.createObjectURL(blob);

export default worker_script;
