import { Fragment, useEffect, useMemo, useState } from 'react';
import { CgSmartphone } from 'react-icons/cg';
import { FaCog, FaRegUser, FaUndo } from 'react-icons/fa';
import {
  HiOutlineClock,
  HiOutlineCog8Tooth,
  HiOutlineSun,
} from 'react-icons/hi2';
import { MdOutlineSensors } from 'react-icons/md';
import { FormattedMessage, useIntl } from 'react-intl';
import Button from '../Button';
import Card from '../Card';
import DeviceTypeSelection from '../DeviceTypeSelection';
import { DeviceTypes } from '../DeviceTypeSelection/DeviceTypeSelection';
import Listbox from '../Listbox';
import type { ListBoxElement } from '../Listbox/Listbox';
import Transition from '../Transition';
import {
  Configcode,
  GenericActivate,
  LightpadConfig,
  type LuxValues,
  NeoConfig,
  PIRHalfAutomatic,
  PIRSteckerschaltung,
  aloneAtWorkConfigs,
  lightpadPirConfigs,
  singleHeadConfig,
  luxConfigs,
  modes,
  doNotChangeMode,
  halfAutomaticMode,
  neoPirConfigs,
} from './bitTypes';

interface SwitchesWrapperProps {
  setMiddleValue: (newMiddleValue: string[]) => void;
  deviceType: DeviceTypes;
  setDeviceType: (newType: DeviceTypes) => void;
}

type ObjectType = { [key: string]: string };

const getEvenedBit = (str: string) => {
  const arr = str.split('').map((s) => Number.parseInt(s, 10));
  let countOnes = 0;
  for (let i = 0; i < arr.length; i += 1) {
    if (arr[i] === 1) {
      countOnes += 1;
    }
  }
  const moduloEight = countOnes % 8;
  let parity = '';
  if (moduloEight === 0) {
    parity = '000';
  }
  if (moduloEight === 1) {
    parity = '001';
  }
  if (moduloEight === 2) {
    parity = '010';
  }
  if (moduloEight === 3) {
    parity = '011';
  }
  if (moduloEight === 4) {
    parity = '100';
  }
  if (moduloEight === 5) {
    parity = '101';
  }
  if (moduloEight === 6) {
    parity = '110';
  }
  if (moduloEight === 7) {
    parity = '111';
  }
  return `${str}${parity}`;
};

const followUpTimes = [
  {
    title: 'Do not change',
    value: null,
  },
  {
    title: '10',
    value: 10,
  },
  {
    title: '30',
    value: 30,
  },
  {
    title: '60',
    value: 60,
  },
  {
    title: '120',
    value: 120,
  },
];

const followUpBytes = {
  10: '00',
  30: '01',
  60: '10',
  120: '11',
} as ObjectType;

const formatTitleFallback = (e: ListBoxElement) => (
  <FormattedMessage id={e.title} />
);

const cascadeBits = {
  0: '000',
  1: '001',
  2: '010',
  3: '011',
  4: '100',
  5: '101',
  6: '110',
  7: '111',
} as ObjectType;

function SwitchesWrapper({
  setMiddleValue,
  deviceType,
  setDeviceType,
}: SwitchesWrapperProps): JSX.Element {
  const intl = useIntl();

  const [mode, setMode] = useState<ListBoxElement>(modes[0] as ListBoxElement);
  const changeModeActive = useMemo(
    () => typeof mode.value === 'number',
    [mode],
  );

  const [neoPIRMode, setNeoPIRMode] = useState<ListBoxElement>(
    neoPirConfigs[0] as ListBoxElement,
  );

  const [luxValue, setLuxValue] = useState<ListBoxElement>(
    luxConfigs[0] as ListBoxElement,
  );
  const changeBrightnessActive = useMemo(
    () => luxValue.value !== null,
    [luxValue],
  );
  const isBrightnessActive = useMemo(
    () => changeBrightnessActive && !!luxValue.value,
    [changeBrightnessActive, luxValue],
  );

  const [lightpadPirConfig, setLightpadPirConfig] = useState<ListBoxElement>(
    lightpadPirConfigs[0] as ListBoxElement,
  );

  const [followUpTime, setFollowUpTime] = useState<ListBoxElement>(
    followUpTimes[0] as ListBoxElement,
  );
  const changeFollowUpTime = useMemo(
    () => typeof followUpTime.value === 'number',
    [followUpTime],
  );

  const [isAloneAtWork, setIsAloneAtWork] = useState<ListBoxElement>(
    aloneAtWorkConfigs[0] as ListBoxElement,
  );
  const changeIsAloneAtWork = useMemo(
    () => typeof isAloneAtWork.value === 'string',
    [isAloneAtWork],
  );

  const [resetAllSettings, setResetAllSettings] = useState(false);

  // only active when the fourth mode is selected (Steckerschaltung)
  const isPlugModeActive = mode.value === 4;
  // only active when the second mode is selected (Halbautomatisch)
  const isHalfAutomatic = mode.value === 2;

  useEffect(() => {
    const bitsArray = [];
    if (
      resetAllSettings // || newInitialization
    ) {
      bitsArray.push(
        `${Configcode.reset}${
          '0' // newInitialization ? '1' : '0'
        }${resetAllSettings ? '1' : '0'}`,
      );
    } else {
      if (changeModeActive || changeBrightnessActive) {
        // Only Light head - bit 0
        // If device type is lightpad can configure it, fallback for neo is '1'
        const lightHead =
          deviceType === DeviceTypes.LIGHTPAD
            ? lightpadPirConfig.value
            : LightpadConfig.both;

        // PIR sensor activation - bit 1-2
        const getLuxSensor = () => {
          if (changeModeActive && !isPlugModeActive) {
            return [1, 2].includes(mode.value as number) // full/half automatic mode
              ? GenericActivate.activate
              : GenericActivate.deactivate;
          }
          return GenericActivate.keep;
        };

        // Regulation - bit 3-4
        const brightnessString = isBrightnessActive
          ? GenericActivate.activate
          : GenericActivate.deactivate;
        const regulation =
          changeBrightnessActive && !isPlugModeActive
            ? brightnessString
            : GenericActivate.keep;
        // Steckerschaltung - bit 5
        const isPluggedString = isPlugModeActive
          ? PIRSteckerschaltung.activate
          : PIRSteckerschaltung.deactivate;

        // Half automatic - bit 6
        const halfAutomatic = isHalfAutomatic
          ? PIRHalfAutomatic.activate
          : PIRHalfAutomatic.deactivate;

        const pirString = `${
          Configcode.pirLux
        }${halfAutomatic}${isPluggedString}${regulation}${getLuxSensor()}${
          lightHead // 1 for neo
        }`;

        bitsArray.push(pirString);
      }

      if (changeFollowUpTime && followUpTime?.value) {
        bitsArray.push(
          `${Configcode.timing}${followUpBytes[followUpTime.value]}`,
        );
      }

      if (changeIsAloneAtWork) {
        bitsArray.push(`${Configcode.aaw}${isAloneAtWork.value}`);
      }

      if (isBrightnessActive) {
        const luxValueString = `${Configcode.luxValue}${luxValue.value}`;
        bitsArray.push(luxValueString);
      }

      if (
        deviceType === DeviceTypes.TWEAK_NEO &&
        neoPIRMode.value !== NeoConfig.keep
      ) {
        bitsArray.push(`${Configcode.neo}${neoPIRMode.value}`);
      }
    }

    const cascadeString = `${Configcode.cascade}${
      cascadeBits[bitsArray.length || 0]
    }`;
    bitsArray.push(cascadeString);

    const evenedBits = bitsArray.map((b) => getEvenedBit(b));
    setMiddleValue(evenedBits);
  }, [
    resetAllSettings,
    isPlugModeActive,
    changeBrightnessActive,
    changeFollowUpTime,
    followUpTime,
    changeIsAloneAtWork,
    isBrightnessActive,
    isAloneAtWork,
    setMiddleValue,
    changeModeActive,
    mode.value,
    lightpadPirConfig,
    isHalfAutomatic,
    neoPIRMode,
    luxValue,
    deviceType,
  ]);
  interface Switch {
    name: string;
    isEnabled?: boolean;
    onSetEnable?: (val: boolean) => void;
    icon: JSX.Element;
    color: {
      text: string;
      color: string;
      fill: string;
      border: string;
    };
    disabled: boolean;
    subSelect?: {
      value: ListBoxElement;
      onValueChange: (e: ListBoxElement) => void;
      options: {
        title: string;
        value: number | LuxValues;
      }[];
      color: string;
      tickColor: string;
      formatTitle?: (e: ListBoxElement) => string;
    };
  }
  const switches = [
    {
      name: 'Reset to factory setting',
      isEnabled: resetAllSettings,
      onSetEnable: (val: boolean) => setResetAllSettings(val),
      icon: <FaUndo className="h-4 w-4 mx-1 my-2" />,
      color: {
        text: 'text-gray-500',
        color: 'bg-gray-500',
        fill: '#6b7280',
        border: 'border-gray-400 focus:border-gray-500',
      },
      // disabled: newInitialization,
    },
    deviceType === DeviceTypes.LIGHTPAD && {
      name: 'Luminaire programming',
      icon: <CgSmartphone className="h-5 w-5 mx-1 my-2" />,
      color: {
        text: 'text-orange-500',
        color: 'bg-orange-500',
        fill: '#ea580c',
        border: 'border-orange-400 focus:border-orange-500',
      },
      disabled: resetAllSettings || deviceType !== DeviceTypes.LIGHTPAD,
      subSelect: {
        value: lightpadPirConfig,
        onValueChange: (e: ListBoxElement) => {
          if (e === lightpadPirConfigs[1] && mode === halfAutomaticMode) {
            setMode(doNotChangeMode);
          }
          setLightpadPirConfig(e);
        },
        options: lightpadPirConfigs,
        color: 'bg-orange-100 text-orange-900',
        tickColor: 'text-orange-600',
      },
    },
    deviceType === DeviceTypes.TWEAK_NEO && {
      name: 'Luminaire programming',
      icon: <MdOutlineSensors className="h-5 w-5 mx-1 my-2" />,
      color: {
        text: 'text-orange-500',
        color: 'bg-orange-500',
        fill: '#ea580c',
        border: 'border-orange-400 focus:border-orange-500',
      },
      disabled: resetAllSettings || deviceType !== DeviceTypes.TWEAK_NEO,
      subSelect: {
        value: neoPIRMode,
        onValueChange: (e: ListBoxElement) => setNeoPIRMode(e),
        options: neoPirConfigs,
        color: 'bg-orange-100 text-orange-900',
        tickColor: 'text-orange-600',
      },
    },
    {
      name: 'On & Off behavior',
      icon: <HiOutlineCog8Tooth className="h-5 w-5 mx-1 my-2" />,
      color: {
        text: 'text-emerald-500',
        color: 'bg-emerald-500',
        fill: '#10b981',
        border: 'border-emerald-400 focus:border-emerald-500',
      },
      disabled: resetAllSettings, // || newInitialization,
      subSelect: {
        value: mode,
        onValueChange: (e: ListBoxElement) => setMode(e),
        options: modes,
        color: 'bg-emerald-100 text-emerald-900',
        tickColor: 'text-emerald-600',
      },
    },
    {
      name: 'Brightness Level',
      icon: <HiOutlineSun className="h-5 w-5 mx-1 my-2" />,
      color: {
        text: 'text-violet-500',
        color: 'bg-violet-500',
        fill: '#8b5cf6',
        border: 'border-violet-400 focus:border-violet-500',
      },
      disabled: resetAllSettings || isPlugModeActive,
      subSelect: {
        value: luxValue,
        onValueChange: (e: ListBoxElement) => setLuxValue(e),
        options: luxConfigs,
        color: 'bg-violet-100 text-violet-900',
        tickColor: 'text-violet-600',
      },
    },
    {
      name: 'Change follow-up time',
      icon: <HiOutlineClock className="h-5 w-5 mx-1 my-2" />,
      color: {
        text: 'text-yellow-500',
        color: 'bg-yellow-500',
        fill: '#eab308',
        border: 'border-yellow-400 focus:border-yellow-500',
      },
      disabled: resetAllSettings, // || newInitialization
      subSelect: {
        value: followUpTime,
        onValueChange: (e: ListBoxElement) => setFollowUpTime(e),
        options: followUpTimes,
        color: 'bg-amber-100 text-amber-900',
        tickColor: 'text-amber-600',
        formatTitle: (e: { title: string; value: number | null }) =>
          typeof e.value === 'number'
            ? intl.formatMessage(
                {
                  id:
                    e.value && e.value > 1
                      ? 'followUpTime-plural'
                      : 'followUpTime-singular',
                },
                { time: e.value },
              )
            : intl.formatMessage({
                id: e.title,
              }),
      },
    },
    {
      name: 'Alone@Work operation',
      icon: <FaRegUser className="h-5 w-5 mx-1 my-2" />,
      color: {
        text: 'text-blue-500',
        color: 'bg-blue-500',
        fill: '#3b82f6',
        border: 'border-blue-400 focus:border-blue-500',
      },
      disabled: resetAllSettings, // || newInitialization
      subSelect: {
        value: isAloneAtWork,
        onValueChange: (e: ListBoxElement) => setIsAloneAtWork(e),
        options: aloneAtWorkConfigs,
        color: 'bg-blue-100 text-blue-900',
        tickColor: 'text-blue-600',
      },
    },
  ].filter((s) => s) as Switch[];

  return (
    <Card className="flex-col">
      <div className="flex flex-grow items-center px-2 gap-2 text-lg">
        <FaCog className="h-4 w-4" />
        <FormattedMessage id="Configuration" />
      </div>
      <div className="flex flex-col gap-2 px-2">
        <DeviceTypeSelection
          deviceType={deviceType}
          setDeviceType={(newDeviceType: DeviceTypes) => {
            // Reset device specific params
            if (newDeviceType === DeviceTypes.LIGHTPAD) {
              setLightpadPirConfig(lightpadPirConfigs[0] as ListBoxElement);
            }
            if (newDeviceType === DeviceTypes.TWEAK_NEO) {
              setNeoPIRMode(neoPirConfigs[0] as ListBoxElement);
            }
            setDeviceType(newDeviceType);
          }}
        />
        {switches.map((sw, idx) => {
          if (!sw) {
            return null;
          }
          const {
            disabled,
            color,
            subSelect,
            name,
            isEnabled,
            onSetEnable,
            icon,
          } = sw;
          // Classes in tailwind.config.js 'safelist' so it is already generated on the fly
          const zIndex = `z-${(switches.length - idx) * 10}`;
          return (
            <Transition show={!disabled} as={Fragment} key={sw.name}>
              <div
                className={`flex flex-col gap-2 relative ${
                  disabled ? 'text-gray-400' : ''
                } ${zIndex}`}
                data-test-id={sw.name}
              >
                <div className="flex items-center gap-2 justify-between">
                  <div className="flex items-center gap-2 w-full">
                    {onSetEnable ? (
                      <Button
                        id={name}
                        className={`flex items-center gap-4 shadow-lg text-aqua rounded-xl ring-1 ring-black/5 shadow-black/10 py-2 px-4 w-full my-2 ${
                          isEnabled ? 'bg-indigo-100 bg-opacity-70' : 'bg-white'
                        }`}
                        onClick={() => onSetEnable?.(!isEnabled)}
                      >
                        <div
                          className={disabled ? 'text-gray-200' : color.text}
                        >
                          {icon}
                        </div>
                        <div>
                          <FormattedMessage id={name} />
                        </div>
                      </Button>
                    ) : (
                      <>
                        <div
                          id={name}
                          className={`flex items-center justify-center w-12 h-12 bg-white shadow-lg text-aqua rounded-xl ring-1 ring-black/5 shadow-black/10 ${
                            disabled ? 'text-gray-200' : color.text
                          }`}
                        >
                          {icon}
                        </div>
                        <FormattedMessage id={name} />
                      </>
                    )}
                  </div>
                </div>
                {subSelect && (
                  <div className="flex items-center gap-2 pl-14">
                    <Listbox
                      dataTestId={`${sw.name}-option-select`}
                      options={subSelect.options}
                      disabledOptions={
                        // [TOOL-135] For Lightpad, disable 'half-automatic' when configuring single head
                        deviceType === DeviceTypes.LIGHTPAD &&
                        lightpadPirConfig === singleHeadConfig
                          ? [halfAutomaticMode]
                          : []
                      }
                      selected={subSelect.value}
                      setSelected={subSelect.onValueChange}
                      formatTitle={subSelect.formatTitle || formatTitleFallback}
                      color={subSelect.color}
                      tickColor={subSelect.tickColor}
                      disabled={disabled}
                    />
                  </div>
                )}
              </div>
            </Transition>
          );
        })}
      </div>
    </Card>
  );
}
export default SwitchesWrapper;
