import {
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
} from '@headlessui/react';
import { HiCheck, HiSelector } from 'react-icons/hi';
import type {
  GenericActivate,
  LightpadConfig,
  LuxValues,
  NeoConfig,
} from '../SwitchesWrapper/bitTypes';

export interface ListBoxElement {
  title: string;
  value:
    | number
    | NeoConfig
    | LuxValues
    | LightpadConfig
    | GenericActivate
    | null;
}

interface ListboxProps {
  options: ListBoxElement[];
  disabledOptions: ListBoxElement[];
  selected: ListBoxElement;
  setSelected: (selected: ListBoxElement) => void;
  color: string;
  tickColor: string;
  formatTitle?: (selected: ListBoxElement) => string | JSX.Element;
  dataTestId?: string;
  disabled?: boolean;
}

export default function ListboxComp({
  options,
  disabledOptions,
  selected,
  setSelected,
  formatTitle = (s) => s.title,
  color,
  tickColor,
  dataTestId,
  disabled = false,
}: ListboxProps): JSX.Element {
  return (
    <Listbox value={selected} onChange={setSelected} disabled={disabled}>
      <div className="relative mt-1 w-full">
        <ListboxButton
          className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm shadow-lg"
          data-test-id={dataTestId}
        >
          <span className="block">{formatTitle(selected)}</span>
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <HiSelector className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </span>
        </ListboxButton>
        <ListboxOptions
          anchor="bottom start"
          className="z-40 mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 sm:text-sm"
        >
          {options.map((el, idx) => {
            const disabled = disabledOptions.includes(el);
            return (
              <ListboxOption
                // eslint-disable-next-line react/no-array-index-key
                key={`key-${el.title}-${el.value}-${idx}`}
                data-test-id={`${dataTestId}-option-${el.title}`}
                className={({ active }) =>
                  `relative cursor-default select-none py-2 pl-10 pr-4 ${
                    disabled
                      ? 'text-gray-400'
                      : active
                        ? color
                        : 'text-gray-900'
                  }`
                }
                value={el}
                disabled={disabled}
              >
                {({ selected: sel }) => (
                  <>
                    <span
                      className={`block ${sel ? 'font-medium' : 'font-normal'}`}
                    >
                      {formatTitle(el)}
                    </span>
                    {sel ? (
                      <span
                        className={`absolute inset-y-0 left-0 flex items-center pl-3 ${tickColor}`}
                      >
                        <HiCheck className="h-5 w-5" aria-hidden="true" />
                      </span>
                    ) : null}
                  </>
                )}
              </ListboxOption>
            );
          })}
        </ListboxOptions>
      </div>
    </Listbox>
  );
}
