export enum Configcode {
  cascade = '0000',
  reset = '0001',
  pirLux = '0010',
  timing = '0011',
  aaw = '0100',
  audio = '0101',
  luxValue = '0110',
  neo = '0111',
}

export enum PIRHalfAutomatic {
  deactivate = '0',
  activate = '1',
}

export enum GenericActivate {
  keep = '00',
  deactivate = '01',
  activate = '10',
}

export enum PIRSteckerschaltung {
  deactivate = '0',
  activate = '1',
}

export enum LuxValues {
  keep = '000',
  lux200 = '001',
  lux300 = '010',
  lux400 = '011',
  lux500 = '100',
  lux600 = '101',
  lux700 = '110',
  lux800 = '111',
}

export const luxConfigs = [
  {
    title: 'Do not change',
    value: null,
  },
  {
    title: 'No Regulation',
    value: 0,
  },
  {
    title: '1',
    value: LuxValues.lux300,
  },
  {
    title: '2',
    value: LuxValues.lux500,
  },
  {
    title: '3',
    value: LuxValues.lux700,
  },
];

export enum NeoConfig {
  keep = '00',
  justRight = '01',
  justLeft = '10',
  both = '11',
}

export const neoPirConfigs = [
  {
    title: 'Do not change',
    value: NeoConfig.keep,
  },
  {
    title: 'Only the right sensor',
    value: NeoConfig.justRight,
  },
  {
    title: 'Only the left sensor',
    value: NeoConfig.justLeft,
  },
  {
    title: 'Both sensors',
    value: NeoConfig.both,
  },
];

export enum LightpadConfig {
  justHead = '0',
  both = '1',
}

export const singleHeadConfig = {
  title: 'Single head',
  value: LightpadConfig.justHead,
};
export const lightpadPirConfigs = [
  {
    title: 'Whole lamp',
    value: LightpadConfig.both,
  },
  singleHeadConfig,
];

export const aloneAtWorkConfigs = [
  {
    title: 'Do not change',
    value: null,
  },
  {
    title: 'Activate',
    value: GenericActivate.activate,
  },
  {
    title: 'Deactivate',
    value: GenericActivate.deactivate,
  },
];

export const halfAutomaticMode = {
  title: 'Half automatic', // PIR Aktive, Steckershaltung off, Halbautomatic on
  value: 2,
};
export const doNotChangeMode = {
  title: 'Do not change',
  value: null,
};

export const modes = [
  doNotChangeMode,
  {
    title: 'Full automatic', // PIR Aktive, Steckershaltung off, Halbautomatic off
    value: 1,
  },
  halfAutomaticMode,
  {
    title: 'Manual', // PIR off, Steckershaltung off, Halbautomatic on
    value: 3,
  },
  {
    title: 'Plug mode', // PIR off, Steckershaltung on, Halbautomatic off
    value: 4,
  },
];
