/* eslint-disable prefer-object-spread */
import en from './en.json';
import de from './de.json';
import fr from './fr.json';
import it from './it.json';

const translations = {
  en: Object.assign({}, en),
  de: Object.assign({}, de),
  fr: Object.assign({}, fr),
  it: Object.assign({}, it),
};

export default translations as any;
